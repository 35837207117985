@charset "utf-8";
/*---------------------------------------*/
#SuccessBuyContainer{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 200;
    background-color: rgba(255, 255, 255,.98);
    display: none;
}
#SuccessBuyContainer #SuccessBuySubContainer{
    margin: auto;
    padding: 0;
    display: block;
}
#SuccessBuyContainer #SuccessBuySubContainer #SuccessBuyImage{
    margin: auto;
    padding: 0;
    display: block;
    width: 150px;
}
#SuccessBuyContainer #SuccessBuySubContainer #SuccessBuyParagraph{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    text-align: center;
    color: #153768;
}
#SuccessBuyContainer #SuccessBuySubContainer #SuccessBuyContinueButton{
    background-color: #153768;
    padding: 7px 60px;
    border-radius: 40px;
    display: block;
    margin: auto;
    border: 1px solid #ffffff;
}
#SuccessBuyContainer #SuccessBuySubContainer #SuccessBuyContinueButton:hover,
#SuccessBuyContainer #SuccessBuySubContainer #SuccessBuyContinueButton:focus{
    transform: scale(1.05);
}
#SuccessBuyContainer #SuccessBuySubContainer #SuccessBuyShopingCart{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    text-align: center;
    color: #666666;
    text-align: center;
    cursor: pointer;
    text-decoration: underline;
}
@media screen and (min-width: 701px) {
    /*---------------------------------------*/
    #SuccessBuyContainer #SuccessBuySubContainer{
        width: 50%;
        min-width: 500px;
    }
    #SuccessBuyContainer #SuccessBuySubContainer #SuccessBuyImage{
        padding-bottom: 3vh;
    }
    #SuccessBuyContainer #SuccessBuySubContainer #SuccessBuyParagraph{
        font-size: 1.5em;
        margin-bottom: 1vh;
    }
    #SuccessBuyContainer #SuccessBuySubContainer #SuccessBuyContinueButton{
        margin-bottom: 4vh;  
        font-size: 1.1em; 
    }
    #SuccessBuyContainer #SuccessBuySubContainer #SuccessBuyShopingCart{
        font-size: .9em;
    }
}
@media screen and (max-width: 700px) {
	/*---------------------------------------*/
    #SuccessBuyContainer #SuccessBuySubContainer{
        width: 50%;
        min-width: 500px;
    }
    #SuccessBuyContainer #SuccessBuySubContainer #SuccessBuyImage{
        padding-bottom: 3vh;
    }
    #SuccessBuyContainer #SuccessBuySubContainer #SuccessBuyParagraph{
        font-size: 1.5em;
        margin-bottom: 1vh;
    }
    #SuccessBuyContainer #SuccessBuySubContainer #SuccessBuyContinueButton{
        margin-bottom: 4vh;  
        font-size: 1.2em; 
    }
    #SuccessBuyContainer #SuccessBuySubContainer #SuccessBuyShopingCart{
        font-size: .9em;
    }
}
